import React, { useState } from 'react'; 
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/logo.jpeg';
import logo1 from '../../assets/logo1.jpeg';

function Navbar({ scrollToSection, refs }) {
  const [hovered, setHovered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const handleNavigation = (sectionRef) => {
    if (location.pathname === '/') {
      // Scroll to the specified section if already on the home page
      scrollToSection(sectionRef);
    } else {
      // Navigate to the home page and then scroll
      navigate('/');
      setTimeout(() => scrollToSection(sectionRef), 300);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" onClick={() => handleNavigation(refs.hero)}>
          <img
            src={hovered ? logo1 : logo}
            alt="Company Logo"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="logo-image"
          />
        </Link>
      </div>
      <ul className="navbar-links">
        <li><a onClick={() => handleNavigation(refs.hero)} className="nav-link">Home</a></li>
        <li><a onClick={() => handleNavigation(refs.careers)} className="nav-link">Careers</a></li>
        <li><a onClick={() => handleNavigation(refs.services)} className="nav-link">Services</a></li>
        <li><a onClick={() => handleNavigation(refs.contactUs)} className="nav-link">Contact Us</a></li>
        <li><a onClick={() => handleNavigation(refs.faq)} className="nav-link">FAQ</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
