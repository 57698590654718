import React from 'react';
import './DataAnalytics.css';

const DataAnalyticsBI = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="data-analytics-container">
      <header className="data-header">
        <h1>Data Analytics & Business Intelligence</h1>
        <p>
          Unlock the power of your data with our analytics and BI solutions. Make informed decisions backed by actionable insights and predictive analytics.
        </p>
      </header>

      <section className="data-content">
        <div>
          <h2>Why Data Analytics?</h2>
          <p>
            Data is the backbone of successful businesses. With data analytics, you can uncover hidden trends, optimize operations, and drive growth through data-driven decisions.
          </p>
          <p>
            Our solutions provide businesses with the tools to collect, analyze, and visualize data effectively, turning raw information into valuable insights.
          </p>
        </div>
        <div>
          <img
            src={require("../../../../assets/DataAnalytics1.jpg")}
            alt="Why Data Analytics"
            className="section-image"
          />
        </div>
      </section>

      <section className="data-benefits">
        <div>
          <h2>Benefits of Data Analytics & BI</h2>
          <p>
            Empower your business with these advantages:
          </p>
          <ul>
            <li><strong>Enhanced Decision-Making:</strong> Gain clarity with real-time insights.</li>
            <li><strong>Operational Efficiency:</strong> Identify inefficiencies and streamline processes.</li>
            <li><strong>Predictive Capabilities:</strong> Forecast trends and stay ahead of the competition.</li>
            <li><strong>Improved Customer Understanding:</strong> Analyze behavior for targeted strategies.</li>
          </ul>
        </div>
        <div>
          <img
            src={require("../../../../assets/DataAnalytics2.jpg")}
            alt="Benefits of Data Analytics"
            className="section-image"
          />
        </div>
      </section>

      <section className="data-process">
        <div>
          <h2>Our Data Analytics Process</h2>
          <p>
            Our approach ensures meaningful and actionable results:
          </p>
          <ol>
            <li><strong>Data Collection:</strong> Gather data from diverse sources securely.</li>
            <li><strong>Data Cleaning:</strong> Ensure quality and reliability by removing inconsistencies.</li>
            <li><strong>Analysis:</strong> Use advanced tools and models to extract insights.</li>
            <li><strong>Visualization:</strong> Present findings through dashboards and reports.</li>
          </ol>
        </div>
        <div>
          <img
            src={require("../../../../assets/DataAnalytics3.jpg")}
            alt="Our Data Analytics Process"
            className="section-image"
          />
        </div>
      </section>

      <section className="data-cta">
        <h2>Transform Your Business with Data</h2>
        <p>
          Ready to turn data into a competitive advantage? Contact us today and discover how our analytics and BI solutions can drive your success.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default DataAnalyticsBI;
