import React from 'react';
import './careers.css';

const Careers = () => (
  <div className="careers">
    <div className="careers-header">
      <h2>Join Our Team</h2>
      <p>Explore exciting career opportunities with us.</p>
      <ul className="vacancies">
        <li>Software Engineer - Full Stack</li>
        <li>Data Analyst</li>
        <li>Project Manager</li>
      </ul>
    </div>
  </div>
);

export default Careers;
