import React from 'react';
import './Cyber.css';

const CyberSecurity = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="cybersecurity-container">
      <header className="cyber-header">
        <h1>Cybersecurity Services</h1>
        <p>
          Protect your business with comprehensive cybersecurity solutions. Our expert team helps you identify vulnerabilities, implement safeguards, and ensure compliance with industry standards.
        </p>
      </header>

      <section className="cyber-content">
        <div>
          <h2>Our Expertise</h2>
          <p>
            We provide end-to-end cybersecurity services to safeguard your critical assets. From risk assessments and vulnerability management to incident response and recovery, our tailored solutions address the unique challenges of your organization.
          </p>
          <p>
            Stay ahead of cyber threats with proactive monitoring, advanced analytics, and cutting-edge technology. Our team ensures your business remains secure in an ever-evolving threat landscape.
          </p>
        </div>
        <div>
          <img
            src={require("../../../../assets/Cyber1.jpg")}
            alt="Our Expertise"
            className="section-image"
          />
        </div>
      </section>

      <section className="cyber-benefits">
        <div>
          <h2>Benefits of Our Cybersecurity Services</h2>
          <p>
            With our cybersecurity solutions, you’ll experience enhanced protection and peace of mind:
          </p>
          <ul>
            <li><strong>Proactive Defense:</strong> Identify and mitigate potential threats before they can impact your operations.</li>
            <li><strong>Compliance Assurance:</strong> Meet industry standards and regulations to protect sensitive information.</li>
            <li><strong>24/7 Monitoring:</strong> Gain continuous monitoring and support to address threats in real time.</li>
            <li><strong>Incident Management:</strong> Ensure quick response and recovery to minimize disruptions in the event of a breach.</li>
          </ul>
        </div>
        <div>
          <img
            src={require("../../../../assets/Cyber2.jpg")}
            alt="Benefits of Our Cybersecurity Services"
            className="section-image"
          />
        </div>
      </section>

      <section className="cyber-process">
        <div>
          <h2>Our Approach to Cybersecurity</h2>
          <p>
            Our strategic methodology ensures comprehensive protection and resilience for your organization:
          </p>
          <ol>
            <li><strong>Assessment:</strong> Conduct thorough audits and risk assessments to identify vulnerabilities.</li>
            <li><strong>Implementation:</strong> Deploy state-of-the-art tools and policies to mitigate risks.</li>
            <li><strong>Monitoring:</strong> Continuously monitor your systems to detect and address threats proactively.</li>
            <li><strong>Response:</strong> Provide immediate support and recovery in case of an incident.</li>
          </ol>
        </div>
        <div>
          <img
            src={require("../../../../assets/Cyber3.jpg")}
            alt="Our Approach to Cybersecurity"
            className="section-image"
          />
        </div>
      </section>

      <section className="cyber-cta">
        <h2>Secure Your Digital Future</h2>
        <p>
          Ready to protect your business from cyber threats? Contact us today to learn more about our customized cybersecurity solutions and ensure the safety of your operations.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default CyberSecurity;
