import React from 'react';
import './ERP.css';

const ERP = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="erp-container">
      <header className="erp-header">
        <h1>Enterprise Resource Planning (ERP) Solutions</h1>
        <p>
          Optimize your business operations with our comprehensive ERP solutions designed to integrate all aspects of your organization, from finance to supply chain management.
        </p>
      </header>

      <section className="erp-features">
        <div>
          <h2>Key Features</h2>
          <p>
            Our ERP solutions offer powerful features to help businesses manage resources effectively. These include:
          </p>
          <ul>
            <li>Real-time data analytics and reporting</li>
            <li>Integrated financial management</li>
            <li>Supply chain and inventory management</li>
            <li>Customer relationship management (CRM)</li>
            <li>Human resources and payroll management</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/ERP1.jpg")} alt="ERP Features" className="section-image" />
        </div>
      </section>

      <section className="erp-benefits">
        <div>
          <h2>Benefits of ERP Systems</h2>
          <p>
            Implementing an ERP system offers numerous advantages that streamline business processes and improve efficiency:
          </p>
          <ul>
            <li><strong>Improved Efficiency:</strong> Automation of repetitive tasks and centralized data reduce operational bottlenecks.</li>
            <li><strong>Better Decision-Making:</strong> Access to real-time data supports strategic planning and accurate forecasting.</li>
            <li><strong>Enhanced Collaboration:</strong> A unified platform encourages cross-departmental collaboration and information sharing.</li>
            <li><strong>Scalability:</strong> Our ERP solutions adapt to growing business needs and can scale seamlessly.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/ERP2.jpg")} alt="Benefits of ERP Systems" className="section-image" />
        </div>
      </section>

      <section className="erp-process">
        <div>
          <h2>Implementation Process</h2>
          <p>
            Our ERP implementation process is structured to ensure smooth and successful deployment:
          </p>
          <ol>
            <li><strong>Needs Assessment:</strong> Understanding your unique requirements and business challenges.</li>
            <li><strong>Solution Design:</strong> Creating a custom ERP blueprint tailored to your workflow.</li>
            <li><strong>Development and Configuration:</strong> Setting up the ERP system and integrating it with existing tools.</li>
            <li><strong>Testing and Quality Assurance:</strong> Rigorous testing to ensure functionality and performance.</li>
            <li><strong>Deployment:</strong> Seamlessly transitioning to the new ERP system with minimal disruption.</li>
            <li><strong>Training and Support:</strong> Training your team and providing ongoing technical support.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/ERP3.jpg")} alt="ERP Process" className="section-image" />
        </div>
      </section>

      <section className="erp-cta">
        <h2>Ready to Transform Your Business with ERP?</h2>
        <p>
          Connect with us today to explore how our ERP solutions can support your business growth. Contact us today to schedule a consultation. Our team is here to understand your needs and offer innovative solutions to help you achieve your goals.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default ERP;
