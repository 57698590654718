import React from 'react';
import './BPO.css';

const BPO = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="bpo-container">
      <header className="bpo-header">
        <h1>Business Process Outsourcing (BPO)</h1>
        <p>
          Streamline your operations and focus on your core business with our comprehensive BPO solutions. We help businesses optimize costs, improve efficiency, and deliver exceptional customer experiences.
        </p>
      </header>

      <section className="bpo-content">
        <div>
          <h2>Our Approach</h2>
          <p>
            Our BPO services are designed to take care of non-core yet essential business functions, allowing you to focus on strategic objectives. We collaborate with you to understand your requirements and deliver tailored outsourcing solutions that align with your goals.
          </p>
          <p>
            From customer support to back-office operations, our team leverages technology and expertise to deliver seamless and efficient processes, ensuring high-quality outcomes at reduced costs.
          </p>
        </div>
        <div>
          <img src={require("../../../../assets/BPO3.jpg")} alt="Our Approach" className="section-image" />
        </div>
      </section>

      <section className="bpo-benefits">
        <div>
          <h2>Benefits of BPO Services</h2>
          <p>
            By outsourcing your business processes, you can gain a competitive edge while enjoying these key benefits:
          </p>
          <ul>
            <li><strong>Cost Efficiency:</strong> Reduce operational costs by leveraging our expertise and economies of scale.</li>
            <li><strong>Enhanced Productivity:</strong> Free up your internal resources to focus on core business activities.</li>
            <li><strong>Scalability:</strong> Quickly adapt to changes in demand with our flexible outsourcing models.</li>
            <li><strong>Access to Expertise:</strong> Benefit from the knowledge and skills of our experienced professionals.</li>
            <li><strong>Improved Customer Experience:</strong> Deliver exceptional service with our customer-focused approach.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/BPO2.jpg")} alt="Benefits of BPO" className="section-image" />
        </div>
      </section>

      <section className="bpo-process">
        <div>
          <h2>Our BPO Process</h2>
          <p>
            We follow a structured process to ensure smooth transition and operation of outsourced functions:
          </p>
          <ol>
            <li><strong>Needs Assessment:</strong> We start by understanding your specific business needs and objectives.</li>
            <li><strong>Process Design:</strong> Our team designs customized workflows tailored to your requirements.</li>
            <li><strong>Implementation:</strong> We set up the necessary infrastructure, train personnel, and establish processes.</li>
            <li><strong>Operations Management:</strong> Our team manages the day-to-day operations, ensuring efficiency and quality.</li>
            <li><strong>Continuous Improvement:</strong> We continuously analyze and improve processes to deliver better outcomes.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/BPO1.jpg")} alt="BPO Process" className="section-image" />
        </div>
      </section>

      <section className="bpo-cta">
        <h2>Partner with Us for BPO Services</h2>
        <p>
          Ready to transform your business with efficient outsourcing solutions? Contact us today to discuss your needs and explore how we can help you achieve your goals.
        </p>
        <a onClick={() => handleNavigation('contactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default BPO;