import React from 'react';
import './ECommerce.css';

const ECommerce = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="ecommerce-container">
      <header className="ecommerce-header">
        <h1>E-Commerce Solutions</h1>
        <p>
          Empower your business with cutting-edge e-commerce solutions. From designing online stores to integrating secure payment gateways, we provide comprehensive services to elevate your digital presence.
        </p>
      </header>

      <section className="ecommerce-content">
        <div>
          <h2>Our Approach</h2>
          <p>
            We craft e-commerce solutions tailored to your business needs, ensuring a seamless shopping experience for your customers. From intuitive user interfaces to robust backend systems, we focus on scalability and efficiency.
          </p>
          <p>
            Our solutions include custom e-commerce platforms, integration with third-party services, and tools for inventory, order, and customer management. With a user-centric design approach, we aim to boost conversions and customer retention.
          </p>
        </div>
        <div>
          <img src={require("../../../../assets/ECommerce2.jpg")} alt="E-Commerce Approach" className="section-image" />
        </div>
      </section>

      <section className="ecommerce-benefits">
        <div>
          <h2>Benefits of Our E-Commerce Solutions</h2>
          <p>
            Investing in a robust e-commerce platform comes with a range of advantages:
          </p>
          <ul>
            <li><strong>Global Reach:</strong> Expand your customer base by making your products and services available online, 24/7.</li>
            <li><strong>Secure Transactions:</strong> Ensure safe and smooth payment processing with integrated payment gateways.</li>
            <li><strong>Customizable Design:</strong> Create a unique online store that reflects your brand identity.</li>
            <li><strong>Scalable Infrastructure:</strong> Handle increased traffic and transactions as your business grows.</li>
            <li><strong>Detailed Analytics:</strong> Gain insights into customer behavior and sales performance with advanced analytics tools.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/ECommerce1.jpg")} alt="E-Commerce Benefits" className="section-image" />
        </div>
      </section>

      <section className="ecommerce-process">
        <div>
          <h2>Our Development Process</h2>
          <p>
            Our structured approach ensures the successful delivery of high-quality e-commerce solutions:
          </p>
          <ol>
            <li><strong>Requirement Analysis:</strong> Understanding your business needs, target audience, and market trends.</li>
            <li><strong>Platform Selection:</strong> Recommending the most suitable platform or building a custom solution.</li>
            <li><strong>Design and Development:</strong> Crafting an intuitive and visually appealing user interface paired with a powerful backend.</li>
            <li><strong>Integration:</strong> Setting up payment gateways, shipping modules, and third-party services.</li>
            <li><strong>Testing and Deployment:</strong> Conducting rigorous testing to ensure seamless performance before launching the platform.</li>
            <li><strong>Maintenance and Support:</strong> Providing ongoing updates, monitoring, and support for your e-commerce solution.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/ECommerce3.jpg")} alt="Development Process" className="section-image" />
        </div>
      </section>

      <section className="ecommerce-cta">
        <h2>Get Started with Our E-Commerce Solutions</h2>
        <p>
          Take your business online with a powerful e-commerce platform. Contact us today to schedule a consultation. Our team is here to understand your needs and offer innovative solutions to help you achieve your goals.
        </p>
        <a onClick={() => handleNavigation('contactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default ECommerce;
