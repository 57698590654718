import React from 'react';
import './Web.css';

const WebDevelopment = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="web-development-container">
      <header className="web-header">
        <h1>Web Development</h1>
        <p>
          Create dynamic, responsive websites tailored to meet the unique needs of your business. Our team delivers custom web development solutions that enhance user engagement and drive digital growth.
        </p>
      </header>

      <section className="web-content">
        <div>
          <h2>Our Approach</h2>
          <p>
            Our development process begins by understanding your business goals and target audience. We design websites that are not only visually appealing but also functional and optimized for performance. Our team collaborates with you to create a user-friendly interface and seamless experience.
          </p>
          <p>
            From responsive design to search engine optimization, we handle every aspect of web development to ensure your website makes a lasting impression. We integrate your brand identity into every element of the design, ensuring consistency and professionalism.
          </p>
        </div>
        <div>
          <img src={require("../../../../assets/Web1.jpg")} alt="Our Approach" className="section-image" />
        </div>
      </section>

      <section className="web-benefits">
        <div>
          <h2>Benefits of Our Web Development</h2>
          <p>
            With our web development services, you'll gain an online presence that’s effective, scalable, and tailored to your business needs:
          </p>
          <ul>
            <li><strong>Responsive Design:</strong> Our websites are optimized for all devices, ensuring an exceptional user experience on mobile, tablet, and desktop.</li>
            <li><strong>SEO-Friendly:</strong> We use best practices in SEO to increase your website’s visibility and reach a broader audience.</li>
            <li><strong>Custom Solutions:</strong> We build websites tailored to your business’s unique needs, focusing on both form and function.</li>
            <li><strong>Enhanced Security:</strong> Our team implements robust security measures to protect your website and its data from vulnerabilities.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/Web2.jpg")} alt="Benefits of Our Web Development" className="section-image" />
        </div>
      </section>

      <section className="web-process">
        <div>
          <h2>Our Development Process</h2>
          <p>
            We follow a structured approach to web development to ensure your project is delivered on time and meets all quality standards:
          </p>
          <ol>
            <li><strong>Discovery & Planning:</strong> We begin with a thorough discovery phase to understand your business needs and set clear objectives.</li>
            <li><strong>Design & Prototyping:</strong> Our design team creates wireframes and mockups, incorporating your feedback to ensure alignment with your vision.</li>
            <li><strong>Development:</strong> Our developers bring the design to life, using the latest technologies and adhering to best practices for web performance.</li>
            <li><strong>Testing & Quality Assurance:</strong> We conduct comprehensive testing to ensure functionality, security, and compatibility across all platforms.</li>
            <li><strong>Launch & Maintenance:</strong> We deploy the website and offer ongoing support and maintenance to keep it updated and running smoothly.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/Web4.jpg")} alt="Our Development Process" className="section-image" />
        </div>
      </section>

      <section className="web-cta">
        <h2>Start Your Web Development Journey</h2>
        <p>
          Ready to build a website that represents your brand and engages your audience? Contact us today to schedule a consultation. Our team is here to understand your needs and offer innovative solutions to help you achieve your goals.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default WebDevelopment;
