import React from 'react';
import './Mobile.css';

const MobileDevelopment = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="mobile-development-container">
      <header className="mobile-header">
        <h1>Mobile App Development</h1>
        <p>
          Build innovative, user-friendly mobile applications tailored to your business needs. 
          Our team specializes in creating cross-platform and native apps that deliver exceptional 
          user experiences on iOS and Android devices.
        </p>
      </header>

      <section className="mobile-content">
        <div>
          <h2>Our Expertise</h2>
          <p>
            We specialize in developing feature-rich mobile applications designed to engage your audience 
            and drive business success. From conceptualization to deployment, we collaborate with you to ensure 
            your app stands out in a competitive market.
          </p>
          <p>
            Whether it's a consumer app or an enterprise solution, our approach focuses on delivering 
            intuitive designs and robust functionality. We integrate modern technologies to create apps 
            that are not only visually appealing but also scalable and secure.
          </p>
        </div>
        <div>
          <img src={require("../../../../assets/Mobile1.jpg")} alt="Our Expertise" className="section-image" />
        </div>
      </section>

      <section className="mobile-benefits">
        <div>
          <h2>Benefits of Our Mobile App Development</h2>
          <p>
            Partner with us to build mobile apps that provide value and deliver measurable results:
          </p>
          <ul>
            <li><strong>Custom Development:</strong> Tailored solutions to meet your unique business needs.</li>
            <li><strong>Cross-Platform Compatibility:</strong> Apps that perform seamlessly across iOS and Android platforms.</li>
            <li><strong>Enhanced Security:</strong> Robust security features to protect user data and app integrity.</li>
            <li><strong>Performance Optimization:</strong> High-performance apps designed to handle heavy workloads and deliver a smooth user experience.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/Mobile3.jpeg")} alt="Benefits of Our Mobile App Development" className="section-image" />
        </div>
      </section>

      <section className="mobile-process">
        <div>
          <h2>Our Development Process</h2>
          <p>
            We employ a comprehensive process to ensure the timely delivery of top-quality mobile applications:
          </p>
          <ol>
            <li><strong>Research & Planning:</strong> Understanding your business objectives and target audience.</li>
            <li><strong>Design & Prototyping:</strong> Crafting wireframes and mockups to visualize your app’s structure and design.</li>
            <li><strong>Development:</strong> Utilizing the latest tools and technologies to develop scalable and reliable applications.</li>
            <li><strong>Testing & QA:</strong> Conducting rigorous testing to ensure functionality, usability, and compatibility.</li>
            <li><strong>Deployment & Support:</strong> Launching your app and providing continuous support and updates.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/Mobile2.jpeg")} alt="Our Development Process" className="section-image" />
        </div>
      </section>

      <section className="mobile-cta">
        <h2>Start Your Mobile App Development Journey</h2>
        <p>
          Ready to turn your mobile app idea into reality? Contact us today to schedule a consultation. 
          Let's work together to create an app that drives engagement and delivers results.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default MobileDevelopment;
