import React from 'react';
import './ContactUs.css';

function ContactUs() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here (e.g., send data to an API or email service)
    alert('Thank you for reaching out. We will get back to you soon!');
  };

  return (
    <section className="contact-us">
      <div className="contact-container">
        {/* Contact Form Section */}
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your message"
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send
            </button>
          </form>
        </div>

        {/* Contact Information Section */}
        <div className="contact-info">
          <h2>Get In Touch</h2>
          <p>
            <strong>Email:</strong>{' '}
            <a href="mailto:astechservices.in@gmail.com">
              astechservices.in@gmail.com
            </a>
          </p>
          <p>
            <strong>Phone:</strong>{' '}
            <a href="tel:+918390828319">+91 83908 28319</a>
          </p>
          <p>
            <strong>Location:</strong> Ravet, Pune, Maharashtra
          </p>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
