// File: src/Components/Services/Details/CustomSoftwareDevelopment/Custom.js

import React from 'react';
import './Custom.css';

const CustomSoftwareDevelopment = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="custom-software-container">
      <header className="custom-header">
        <h1>Custom Software Development</h1>
        <p>
          Discover tailored software solutions designed to align precisely with your business objectives. We help turn ideas into innovative applications that optimize processes, boost productivity, and ensure smooth operational flow.
        </p>
      </header>

      <section className="custom-content">
        <div>
          <h2>Our Approach</h2>
          <p>
            At our company, we recognize that every business has unique needs. Off-the-shelf software often lacks the flexibility required to meet specific requirements, which is why we provide custom solutions crafted to your goals. Our team takes a collaborative approach, starting with a deep dive into your business requirements, workflows, and growth plans to create a software roadmap.
          </p>
          <p>
            Whether you’re looking for a solution to improve internal processes, enhance customer experiences, or expand your digital capabilities, we can develop software applications that integrate seamlessly into your existing systems. Our agile methodology ensures continuous feedback and incremental improvements, keeping you engaged throughout the development journey.
          </p>
        </div>
        <div>
          <img src={require("../../../../assets/Custom3.jpg")} alt="Our Approach" className="section-image" />
        </div>
      </section>

      <section className="custom-benefits">
        <div>
          <h2>Benefits of Custom Software</h2>
          <p>
            Custom software development provides several benefits compared to ready-made applications, enabling businesses to work more efficiently and scale effectively. Here’s why custom solutions can make a transformative difference for your organization:
          </p>
          <ul>
            <li><strong>Tailored to Your Needs:</strong> Custom software addresses the specific challenges and processes unique to your business, creating a more effective and intuitive solution.</li>
            <li><strong>Scalability:</strong> As your business grows, your software can grow with it, adapting to handle increased workloads and new functionalities without the need for replacement.</li>
            <li><strong>Improved Integration:</strong> Custom applications can be designed to work seamlessly with your existing systems and third-party services, providing a unified and efficient workflow.</li>
            <li><strong>Enhanced Security:</strong> Customized applications allow for robust security protocols specific to your needs, reducing the risk of vulnerabilities associated with generic software.</li>
            <li><strong>Cost Efficiency in the Long Term:</strong> While custom software may have a higher initial cost, it eliminates the recurring expenses of licensing and subscription fees associated with off-the-shelf software, offering a cost-effective solution over time.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/Custom2.jpg")} alt="Benefits of Custom Software" className="section-image" />
        </div>
      </section>

      <section className="custom-process">
        <div>
          <h2>Our Development Process</h2>
          <p>
            Our custom software development process is designed to maximize collaboration, flexibility, and efficiency. Here’s an outline of our process to ensure that your project is completed on time and on budget:
          </p>
          <ol>
            <li><strong>Initial Consultation:</strong> We begin with a comprehensive consultation to understand your business needs, goals, and the challenges you aim to solve with a custom software solution.</li>
            <li><strong>Project Planning:</strong> Our team creates a detailed project roadmap, defining the scope, timeline, and resources needed to meet your requirements.</li>
            <li><strong>Design Phase:</strong> We develop wireframes and prototypes based on your feedback, ensuring that the design aligns with your vision and user experience standards.</li>
            <li><strong>Development:</strong> Our skilled developers build the application using cutting-edge technologies, following agile principles for incremental progress and ongoing feedback.</li>
            <li><strong>Quality Assurance:</strong> Rigorous testing is conducted to guarantee functionality, security, and performance, ensuring that the application meets your standards.</li>
            <li><strong>Deployment & Training:</strong> Once approved, the software is deployed to your environment. We offer training to your team, ensuring smooth adoption and optimal usage.</li>
            <li><strong>Ongoing Support & Maintenance:</strong> We provide ongoing support and maintenance to ensure that your software continues to perform as expected, with options for enhancements as your business evolves.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/Custom1.jpg")} alt="Our Process" className="section-image" />
        </div>
      </section>

      <section className="custom-cta">
        <h2>Get Started with Custom Software Development</h2>
        <p>
          Ready to enhance your business with a tailored software solution? Contact us today to schedule a consultation. Our team is here to understand your needs and offer innovative solutions to help you achieve your goals.
        </p>
        <a onClick={() => handleNavigation('contactUs')} className="nav-link">Contact Us</a>
      </section>
    </div>
  );
};

export default CustomSoftwareDevelopment;
