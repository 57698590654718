import React, { useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Hero from './Components/Hero/Hero';
import CompanyOverview from './Components/Company Overview/CompanyOverview';
import Careers from './Components/Careers/Careers';
import Services from './Components/Services/Services';
import ContactUs from './Components/Contact Us/ContactUs';
import FAQ from './Components/FAQ/FAQ';
import Legal from './Components/Legal/Legal';
import Footer from './Components/Footer/Footer';
import CustomSoftwareDevelopment from './Components/Services/Details/CustomSoftwareDevelopment/Custom';
import WebDevelopment from './Components/Services/Details/Web Development/Web'; 
import ERP from './Components/Services/Details/ERP/ERP';
import BPO from './Components/Services/Details/BPO/BPO';
import ECommerce from './Components/Services/Details/E-Commerce/ECommerce';
import Mobile from './Components/Services/Details/Mobile App Development/Mobile';
import CloudSolutions from './Components/Services/Details/Cloud Solutions/Cloud';
import CyberSecurity from './Components/Services/Details/Cybersecurity Services/Cyber';
import ITConsulting from './Components/Services/Details/IT Consulting/IT-Consulting';
import DigitalMarketing from './Components/Services/Details/Digital Marketing/Digital';
import DataAnalyticsBI from './Components/Services/Details/Data Analytics/DataAnalytics';
import TechnicalSupport from './Components/Services/Details/Technical Support/Technical';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function App() {
  const heroRef = useRef(null);
  const companyOverviewRef = useRef(null);
  const careersRef = useRef(null);
  const servicesRef = useRef(null);
  const contactUsRef = useRef(null);
  const faqRef = useRef(null);
  const legalRef = useRef(null);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Invalid ref passed to scrollToSection');
    }
  };

  return (
    <Router>
      <div className="App">
        <Navbar
          scrollToSection={scrollToSection}
          refs={{
            hero: heroRef,
            companyOverview: companyOverviewRef,
            careers: careersRef,
            services: servicesRef,
            contactUs: contactUsRef,
            faq: faqRef,
            legal: legalRef,
          }}
        />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div ref={heroRef}>
                  <Hero onGetStartedClick={() => scrollToSection(contactUsRef)} /> {/* Pass scroll function */}
                </div>
                <div ref={companyOverviewRef}>
                  <CompanyOverview />
                </div>
                <div ref={careersRef}>
                  <Careers />
                </div>
                <div ref={servicesRef}>
                  <Services />
                </div>
                <div ref={contactUsRef}>
                  <ContactUs />
                </div>
                <div ref={faqRef}>
                  <FAQ />
                </div>
                <div ref={legalRef}>
                  <Legal />
                </div>
              </>
            }
          />

          {/* Routes for detailed service pages */}
          <Route path="/custom-software-development" element={<CustomSoftwareDevelopment />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/erp-solutions" element={<ERP />} />
          <Route path="/ecommerce-solutions" element={<ECommerce />} />
          <Route path="/business-process-outsourcing" element={<BPO />} />
          <Route path="/mobile-app-development" element={<Mobile/>} />
          <Route path="/cloud-solutions" element={<CloudSolutions/>} />
          <Route path= "/cybersecurity-services" element={<CyberSecurity/>} />
          <Route path='/it-consulting' element={<ITConsulting/>} />
          <Route path='/digital-marketing' element={<DigitalMarketing/>} />
          <Route path='/data-analytics' element={<DataAnalyticsBI/>} />
          <Route path='/technical-support' element={<TechnicalSupport/>} />
        </Routes>

        {/* Footer displayed on all pages */}
        <Footer />

        {/* Fixed WhatsApp and Call icons */}
        <div className="fixed-icons">
          <a href="https://wa.me/+918390828319" target="_blank" rel="noopener noreferrer" className="icon whatsapp-icon">
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <a href="tel:+918390828319" className="icon call-icon">
            <FontAwesomeIcon icon={faPhoneAlt} />
          </a>
        </div>
      </div>
    </Router>
  );
}

export default App;
