import React from 'react';
import './Technical.css';

const TechnicalSupport = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="technical-support-container">
      <header className="tech-header">
        <h1>Technical Support & Maintenance</h1>
        <p>
          Ensure uninterrupted operations with our reliable technical support and maintenance services. From troubleshooting to proactive maintenance, we've got you covered.
        </p>
      </header>

      <section className="tech-content">
        <div>
          <h2>Why Choose Our Services?</h2>
          <p>
            Technology drives your business, and we ensure it runs smoothly. Our technical support services focus on minimizing downtime and maximizing efficiency.
          </p>
          <p>
            We provide comprehensive support for software, hardware, and systems, offering rapid response and long-term solutions to keep your operations running.
          </p>
        </div>
        <div>
          <img
            src={require("../../../../assets/Technical1.jpg")}
            alt="Why Technical Support"
            className="section-image"
          />
        </div>
      </section>

      <section className="tech-benefits">
        <div>
          <h2>Benefits of Our Support & Maintenance</h2>
          <p>
            Our technical support services provide a range of benefits designed
            to keep your business operating smoothly:
          </p>
          <ul>
            <li>
              <strong>24/7 Availability:</strong> Around-the-clock support
              ensures you are never left stranded during critical situations.
            </li>
            <li>
              <strong>Proactive Maintenance:</strong> Regular system checks and
              updates prevent potential issues before they escalate.
            </li>
            <li>
              <strong>Cost Savings:</strong> Avoid unexpected downtime and
              expensive repairs with our preventive approach.
            </li>
            <li>
              <strong>Expertise on Demand:</strong> Access a team of skilled
              professionals with in-depth technical knowledge and
              problem-solving capabilities.
            </li>
            <li>
              <strong>Faster Resolutions:</strong> Reduced turnaround times for
              troubleshooting and issue resolution to minimize disruptions.
            </li>
            <li>
              <strong>Customized Solutions:</strong> Tailored maintenance plans
              and support services designed to fit your business needs.
            </li>
            <li>
              <strong>Data Security:</strong> Regular security checks and
              updates to protect your systems against vulnerabilities and cyber
              threats.
            </li>
            <li>
              <strong>Scalability:</strong> Support that grows with your
              business, ensuring your IT infrastructure evolves to meet changing
              demands.
            </li>
            <li>
              <strong>Comprehensive Reporting:</strong> Transparent performance
              metrics and detailed reports to keep you informed about system
              health and updates.
            </li>
          </ul>
          <p>
            Our mission is to provide a seamless technical environment so you
            can focus on your core business activities without worrying about IT
            challenges.
          </p>
        </div>
        <div>
          <img
            src={require("../../../../assets/Technical2.jpg")}
            alt="Benefits of Technical Support"
            className="section-image"
          />
        </div>
      </section>

      <section className="tech-process">
        <div>
          <h2>Our Technical Support Process</h2>
          <p>
            Here's how we ensure seamless support:
          </p>
          <ol>
            <li><strong>Issue Identification:</strong> Quickly diagnose problems.</li>
            <li><strong>Resolution:</strong> Implement solutions to restore functionality.</li>
            <li><strong>Monitoring:</strong> Continuously monitor systems to prevent future issues.</li>
            <li><strong>Feedback & Reporting:</strong> Provide clear updates and gather feedback.</li>
          </ol>
        </div>
        <div>
          <img
            src={require("../../../../assets/Technical3.jpg")}
            alt="Our Support Process"
            className="section-image"
          />
        </div>
      </section>

      <section className="tech-cta">
        <h2>Stay Worry-Free with Our Support</h2>
        <p>
          Don't let technical issues slow you down. Contact us today to learn how our technical support and maintenance services can keep your business running smoothly.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Get Started</a>
      </section>
    </div>
  );
};

export default TechnicalSupport;
