import React from 'react';
import './Digital.css';

const DigitalMarketing = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="digital-marketing-container">
      <header className="digital-header">
        <h1>Digital Marketing Services</h1>
        <p>
          Enhance your brand's online presence with our comprehensive digital marketing solutions. From SEO to social media, we deliver measurable results tailored to your business needs.
        </p>
      </header>

      <section className="digital-content">
        <div>
          <h2>Why Digital Marketing?</h2>
          <p>
            In today's digital-first world, having a robust online presence is essential. Digital marketing helps businesses connect with their audience, drive engagement, and boost sales through targeted strategies and data-driven insights.
          </p>
          <p>
            Whether you're looking to grow your reach, generate leads, or establish your brand, our services are designed to help you achieve your goals effectively.
          </p>
        </div>
        <div>
          <img
            src={require("../../../../assets/Digital1.jpg")}
            alt="Why Digital Marketing"
            className="section-image"
          />
        </div>
      </section>

      <section className="digital-benefits">
        <div>
          <h2>Benefits of Digital Marketing</h2>
          <p>
            Partner with us to leverage these benefits:
          </p>
          <ul>
            <li><strong>Increased Reach:</strong> Connect with a global audience using diverse digital channels.</li>
            <li><strong>Cost Efficiency:</strong> Achieve better ROI compared to traditional marketing methods.</li>
            <li><strong>Real-Time Insights:</strong> Monitor performance and adjust campaigns dynamically.</li>
            <li><strong>Brand Authority:</strong> Build trust and credibility in your industry.</li>
          </ul>
        </div>
        <div>
          <img
            src={require("../../../../assets/Digital2.jpg")}
            alt="Benefits of Digital Marketing"
            className="section-image"
          />
        </div>
      </section>

      <section className="digital-process">
        <div>
          <h2>Our Digital Marketing Process</h2>
          <p>
            We follow a strategic approach to deliver impactful results:
          </p>
          <ol>
            <li><strong>Analysis:</strong> Understand your business goals and target audience.</li>
            <li><strong>Strategy:</strong> Develop a customized marketing plan with clear objectives.</li>
            <li><strong>Execution:</strong> Launch and manage campaigns across multiple channels.</li>
            <li><strong>Optimization:</strong> Continuously improve performance based on analytics.</li>
          </ol>
        </div>
        <div>
          <img
            src={require("../../../../assets/Digital3.jpg")}
            alt="Our Digital Marketing Process"
            className="section-image"
          />
        </div>
      </section>

      <section className="digital-cta">
        <h2>Take Your Brand to the Next Level</h2>
        <p>
          Ready to transform your business with digital marketing? Contact us today to discuss your goals and explore how we can help.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Get Started</a>
      </section>
    </div>
  );
};

export default DigitalMarketing;
