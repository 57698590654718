import React from 'react';
import './IT-Consulting.css';

const ITConsulting = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="it-consulting-container">
      <header className="it-header">
        <h1>IT Consulting Services</h1>
        <p>
          Empower your business with expert IT consulting services. We help you align technology strategies with your business objectives to drive innovation and efficiency.
        </p>
      </header>

      <section className="it-content">
        <div>
          <h2>Why Choose IT Consulting?</h2>
          <p>
            IT consulting bridges the gap between your business goals and the technology that powers them. Our services include IT strategy development, technology assessments, and implementation of cutting-edge solutions tailored to your needs.
          </p>
          <p>
            Whether you're scaling operations, adopting cloud technologies, or improving security, our IT consulting expertise ensures your success in an ever-changing digital landscape.
          </p>
        </div>
        <div>
          <img
            src={require("../../../../assets/ITConsulting1.jpg")}
            alt="Why Choose IT Consulting?"
            className="section-image"
          />
        </div>
      </section>

      <section className="it-benefits">
        <div>
          <h2>Benefits of Our IT Consulting Services</h2>
          <p>
            Partner with us to experience unparalleled benefits:
          </p>
          <ul>
            <li><strong>Strategic Alignment:</strong> Ensure your IT investments align with your business goals.</li>
            <li><strong>Cost Efficiency:</strong> Optimize resources and reduce overhead with tailored solutions.</li>
            <li><strong>Expert Insights:</strong> Leverage the knowledge of seasoned IT professionals.</li>
            <li><strong>Scalable Solutions:</strong> Design systems that grow with your business.</li>
          </ul>
        </div>
        <div>
          <img
            src={require("../../../../assets/ITConsulting2.jpg")}
            alt="Benefits of Our IT Consulting Services"
            className="section-image"
          />
        </div>
      </section>

      <section className="it-process">
        <div>
          <h2>Our IT Consulting Approach</h2>
          <p>
            We follow a proven methodology to deliver exceptional results:
          </p>
          <ol>
            <li><strong>Discovery:</strong> Understand your business needs and challenges.</li>
            <li><strong>Planning:</strong> Develop a custom IT roadmap with clear milestones.</li>
            <li><strong>Implementation:</strong> Deploy solutions seamlessly with minimal disruption.</li>
            <li><strong>Optimization:</strong> Continuously monitor and improve your IT systems.</li>
          </ol>
        </div>
        <div>
          <img
            src={require("../../../../assets/ITConsulting3.jpg")}
            alt="Our IT Consulting Approach"
            className="section-image"
          />
        </div>
      </section>

      <section className="it-cta">
        <h2>Transform Your Business with IT Consulting</h2>
        <p>
          Let us help you unlock the full potential of your technology. Contact us today to learn more about our IT consulting services and how we can support your business goals.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default ITConsulting;
