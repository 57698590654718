import React from 'react';
import './Cloud.css';

const CloudSolutions = ({ scrollToSection, refs }) => {
  const handleNavigation = (section) => {
    if (refs && refs[section]) {
      scrollToSection(refs[section]);
    } else {
      console.error(`Section ${section} does not exist in refs`);
    }
  };

  return (
    <div className="cloud-solutions-container">
      <header className="cloud-header">
        <h1>Cloud Solutions</h1>
        <p>
          Leverage the power of the cloud to enhance scalability, reliability, and cost-effectiveness for your business. Our cloud solutions are designed to streamline operations and accelerate innovation.
        </p>
      </header>

      <section className="cloud-content">
        <div>
          <h2>What We Offer</h2>
          <p>
            From cloud migration to managing hybrid environments, our solutions are tailored to your unique needs. We ensure seamless integration, secure architecture, and optimal performance.
          </p>
          <p>
            Whether you’re adopting the cloud for the first time or optimizing existing services, our team helps you harness its full potential to drive business growth and agility.
          </p>
        </div>
        <div>
          <img src={require("../../../../assets/Cloud1.jpg")} alt="What We Offer" className="section-image" />
        </div>
      </section>

      <section className="cloud-benefits">
        <div>
          <h2>Benefits of Our Cloud Solutions</h2>
          <p>
            Experience the advantages of a cloud-first strategy with our comprehensive services:
          </p>
          <ul>
            <li><strong>Scalability:</strong> Easily scale resources up or down based on your business demands.</li>
            <li><strong>Cost Efficiency:</strong> Reduce infrastructure costs with pay-as-you-go cloud models.</li>
            <li><strong>Enhanced Security:</strong> We implement advanced security measures to protect your data and ensure compliance.</li>
            <li><strong>Global Accessibility:</strong> Access your applications and data from anywhere, at any time.</li>
          </ul>
        </div>
        <div>
          <img src={require("../../../../assets/Cloud2.jpg")} alt="Benefits of Our Cloud Solutions" className="section-image" />
        </div>
      </section>

      <section className="cloud-process">
        <div>
          <h2>Our Process</h2>
          <p>
            We follow a structured approach to deliver seamless cloud solutions:
          </p>
          <ol>
            <li><strong>Assessment:</strong> Understanding your business goals and evaluating your current infrastructure.</li>
            <li><strong>Strategy:</strong> Developing a customized cloud adoption or optimization strategy.</li>
            <li><strong>Migration:</strong> Ensuring smooth transition of applications and data to the cloud.</li>
            <li><strong>Optimization:</strong> Continuously improving performance and reducing operational costs.</li>
            <li><strong>Management:</strong> Providing ongoing support, monitoring, and updates to ensure reliability.</li>
          </ol>
        </div>
        <div>
          <img src={require("../../../../assets/Cloud3.jpg")} alt="Our Process" className="section-image" />
        </div>
      </section>

      <section className="cloud-cta">
        <h2>Transform Your Business with the Cloud</h2>
        <p>
          Ready to embrace the cloud and unlock new opportunities? Contact us today to discuss your requirements and discover how our solutions can empower your business.
        </p>
        <a onClick={() => handleNavigation('ContactUs')} className="cta-button">Contact Us</a>
      </section>
    </div>
  );
};

export default CloudSolutions;
